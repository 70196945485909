<template>
    <div class="radio-wrap">
        <div v-for="index in parseInt(size)" :key="index" class="grade-list-item">
            <label class="grade-label">
                <input
                    v-on:change="emitResult(index)"
                    type="radio"
                    :value="index + '-' + code"
                    class="grade-radio"
                    :name="index + '-' + code"
                    v-model="picked"
                />
                <span class="checkmark"></span>
                <p class="grade-index" :class="fullName ? `grade${index}` : ''">{{ index }}</p>
            </label>
            <hr v-if="index === 1" :class="alertLine ? 'alert-line grades-line first-line' : 'grades-line first-line'" />
            <hr v-else-if="index === size" :class="alertLine ? 'alert-line grades-line last-line' : 'grades-line last-line'" />
            <hr v-else :class="alertLine ? 'alert-line grades-line' : 'grades-line'" />
            <div class="line-rate" v-if="fullName" :class="posi(index)"></div>
            <!-- :class="{ 'badRate': fullName }" -->
            <div class="grade-limit paragraph mobileRate firstRate" v-if="rateLabe && (fullName ? index === 2 : index === 1)">
                {{ $t("grades.bad") }}
            </div>
            <!-- :class="{ 'normRate': fullName }" -->
            <div class="grade-limit paragraph mobileRate normmobileRate secondRate" v-if="index === 6 && fullName">
                {{ $t("grades.ok") }}
            </div>
            <!-- :class="{ 'goodRate': fullName }" -->
            <div class="grade-limit paragraph hideMobile thirdRate" v-if="index === 9 && fullName">{{ $t("grades.good") }}</div>
            <div class="grade-limit paragraph mobileRate lastRate" v-if="rateLabe && index === size">{{ $t("grades.great") }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ThePointsbar",
        props: {
            size: Number,
            code: String,
            alertLine: {
                default: false,
            },
            fullName: {
                default: false,
            },
            rateLabe: {
                default: true,
            },
        },
        emits: ["change", "alert"],
        data: function () {
            return {
                picked: "",
                rateArr: [
                    {
                        name: "bad",
                        index: [1, 2, 3],
                    },
                    {
                        name: "norm",
                        index: [4, 5, 6, 7, 8],
                    },
                    {
                        name: "good",
                        index: [9],
                    },
                    {
                        name: "perfect",
                        index: [10],
                    },
                ],
            };
        },
        methods: {
            emitResult(index) {
                this.$emit("change", index);
                this.$emit("alert", false);
                // this.alertLine = false;
            },
            posi(index) {
                let sup = "";
                if (index == 9 || index == 10) {
                    return "lastelem";
                }
                this.rateArr.forEach((el) => {
                    if (el.index.includes(index)) {
                        if (el.index[0] == index) sup = "firstposi";
                        if (el.index[el.index.length - 1] == index) sup = "lastposi";
                    }
                });
                return sup;
            },
        },
    };
</script>

<style scoped lang="scss">
    .grade-limit {
        width: 100%;
        display: block;
        text-align: center;
    }

    .grade-label {
        display: block;
        position: relative;
        color: darkgray;
        text-align: left;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 0;
        height: 26px;
        width: 26px;
        background-color: #eee;
        border-radius: 50%;
        z-index: 3;
        cursor: pointer;
        left: 50%;
        transform: translateX(-50%);
    }

    .grade-label:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a orange background */
    .grade-label input:checked ~ .checkmark {
        background-color: #ff7900;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .grade-label input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .grade-label .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }

    span:last-child label hr {
        width: 50%;
    }

    span:first-child label hr {
        width: 50%;
        margin-left: 50%;
    }

    .radio-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 3em 0;
    }

    .grade-label {
        display: block;
        position: relative;
        font-size: medium;
        color: darkgray;
        text-align: center;
    }

    .grade-list-item {
        position: relative;
        list-style: none;
        display: block;
        width: 100%;
    }

    .grade-index {
        /* padding-left: 23px; */
    }

    .grade-radio {
        margin: 1em auto;
        display: block;
        position: relative;
        z-index: 2;
    }

    .grades-line {
        position: absolute;
        top: 16px;
        width: 100%;
        z-index: 1;
    }

    .first-line {
        width: 50%;
        margin-left: 55%;
    }

    .last-line {
        width: 55%;
    }

    .alert-line {
        color: #ff4a00;
        border: solid 1px #ff4a00;
    }

    @media only screen and (max-width: 320px) and (max-height: 568px) and (-webkit-device-pixel-ratio: 2),
        only screen and (max-width: 375px) and (max-height: 667px) and (-webkit-device-pixel-ratio: 2),
        only screen and (max-width: 375px) and (max-height: 812px),
        only screen and (max-width: 414px) and (max-height: 736px) and (-webkit-device-pixel-ratio: 3),
        only screen and (max-width: 411px) and (max-height: 731px),
        only screen and (max-width: 411px) and (max-height: 823px) {
        .radio-wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-evenly;
            margin: 0.5em 0;
            width: 100%;
        }

        .grade-list-item {
            position: relative;
            flex-grow: 1;
            list-style: none;
            display: block;
            width: 100%;
        }

        .grade-label {
            display: block;
            position: relative;
            font-size: smaller;
            color: darkgray;
            text-align: center;
        }

        .grade-limit {
            position: absolute;
            width: 100%;
            display: block;
            text-align: center;
            font-size: smaller;
            font-size: 12px;
        }

        .checkmark {
            position: absolute;
            top: 2px;
            height: 17px;
            width: 17px;
            background-color: #eee;
            border-radius: 50%;
            z-index: 3;
        }

        .grade-label .checkmark:after {
            top: 5px;
            left: 5px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: white;
        }

        .grades-line {
            position: absolute;
            top: 14px;
            width: 100%;
            z-index: 1;
        }

        .position-left {
            text-align: right;
            right: 10px;
        }

        .position-right {
            text-align: right;
            right: 0;
        }

        .grade-index {
            /* padding-left: 12px; */
        }

        .first-line {
            width: 50%;
            margin-left: 55%;
        }

        .last-line {
            width: 55%;
        }
    }

    .firstRate {
        color: red;
    }

    .secondRate {
        color: rgb(255, 183, 0);
    }

    .thirdRate {
        color: #16a085;
    }

    .lastRate {
        color: rgb(1, 178, 1);
    }

    .grade1,
    .grade2,
    .grade3 {
        color: red;
    }

    .grade7,
    .grade6,
    .grade4,
    .grade8,
    .grade5 {
        color: rgb(255, 183, 0);
    }

    .grade9 {
        color: #16a085;
    }

    .grade10 {
        color: rgb(1, 178, 1);
    }

    .line-rate {
        height: 1px;
        width: 100%;
        background: rgb(181, 181, 181);
        position: absolute;
        bottom: 25px;
        left: 0;

        &.firstposi {
            left: 50%;

            &::before {
                position: absolute;
                content: "";
                height: 10px;
                width: 1px;
                background: rgb(181, 181, 181);
                left: 0;
                bottom: 0;
            }
        }

        &.lastposi {
            left: auto;
            right: 50%;

            &::after {
                position: absolute;
                content: "";
                height: 10px;
                width: 1px;
                background: rgb(181, 181, 181);
                right: 0;
                bottom: 0;
            }
        }
    }

    .badRate {
        position: absolute;
        left: 93px;
    }

    .normRate {
        position: absolute;
        left: 145px;
    }

    .goodRate {
        position: absolute;
        left: 50px;
    }

    @media only screen and (max-width: 900px) {
        .badRate {
            left: 85px;
        }

        .normRate {
            left: 125px;
        }

        .goodRate {
            left: 40px;
        }
    }

    @media only screen and (max-width: 770px) {
        .badRate {
            left: 85px;
        }

        .normRate {
            left: 85px;
        }

        .goodRate {
            left: 40px;
        }
    }

    @media only screen and (max-width: 670px) {
        .badRate {
            left: 55px;
        }

        .normRate {
            left: 65px;
        }

        .goodRate {
            left: 20px;
        }

        .line-rate {
            bottom: 5px;
        }

        .mobileRate {
            position: absolute;
            bottom: -15px;
            left: -5px;
        }
        .normmobileRate {
            left: -16px;
        }
        .grade-limit {
            font-size: 12px;
        }
    }

    .lastelem {
        display: none;
    }
</style>
